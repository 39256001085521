import React from "react";

export const TranslationContext = React.createContext();

export const translations = {
  en: {
    loginButton: "Sign in",
    login: "Sign in",
    registerButton: "Sign up",
    authorize: "Sign in",
    emailPlaceholder: "Enter email",
    passwordPlaceholder: "Enter password",
    namePlaceholder: "Enter your username",
    register: "Sign up",
    // logout: "Log out",
    toMain: "Go back",
    notFound: "404 - not found",
    password: "Password",
    username: "Username",
    // alreadyRegister: "Already a member?",
    successText: "Registration successfully completed!",
    // unsuccessText: "Oops, something went wrong! Please try again.",
    // newPlace: "New place",
    // createButton: "Create",
    // cardName: "Title",
    // cardLink: "Image link",
    // confirmText: "Are you sure?",
    // updateAvatar: "Change userpic",
    // saveAvatar: "Save",
    // updateBio: "Edit profile",
    // saveBio: "Save",
    // nameInput: "Name",
    // aboutInput: "About me",
    loading: "Loading...",
    or: "or",
    main: "Home",
    yandex: "Practicum by Yandex",
    savedArticles: "Saved articles",
    removeFromSaved: "Remove from saved",
    signInToSave: "Sign in to save articles",
    isSearching: "Searching for news...",
    notFoundTitle: "Nothing found",
    notFoundText: "Sorry, but nothing matched your search terms.",
    errorTitle: "Server Error",
    errorText: "Sorry, something went wrong during the request. There may be a connection issue or the server may be down. Please try again later.",
    results: "Search results",
    showMore: "Show more",
    collapse: "Collapse",
    youHave: "you have",
    and: "and",
    savedArticle: "saved article",
    savedArticle2: "saved articles",
    savedArticle3: "saved articles",
    byKeyword: "By keyword: ",
    byKeywords: "By keywords: ",
    more: " more",
    more2: " more",
    more3: " more",
    title: "What's going on in the world?",
    subtitle: "Find the latest news on any topic and save them in your personal account.",
    searchPlaceholder: "Enter topic",
    searchButton: "Search",
    about: "About the author",
    aboutMe: "Hey there! I'm Menachem Neiman, Front-End Developer, studied at Yandex.practicum.",
    aboutMe2: "I work mostly with React, Express, and Node, and am fluent in JavaScript, HTML, and CSS.",
  },
  ru: {
    loginButton: "Войти",
    login: "Вход",
    registerButton: "Зарегистрироваться",
    authorize: "Авторизоваться",
    emailPlaceholder: "Введите почту",
    passwordPlaceholder: "Введите пароль",
    namePlaceholder: "Введите своё имя",
    register: "Регистрация",
    logout: "Выйти",
    toMain: "Вернуться на главную",
    notFound: "404 - Страница не найдена",
    password: "Пароль",
    username: "Имя",
    alreadyRegister: "Уже зарегистрированы?",
    successText: "Пользователь успешно зарегистрирован!",
    unsuccessText: "Что-то пошло не так! Попробуйте ещё раз.",
    newPlace: "Новое место",
    createButton: "Создать",
    cardName: "Название",
    cardLink: "Ссылка на картинку",
    confirmText: "Вы уверены?",
    updateAvatar: "Обновить аватар",
    saveAvatar: "Обновить",
    updateBio: "Редактировать профиль",
    saveBio: "Сохранить",
    nameInput: "Имя",
    aboutInput: "О себе",
    loading: "Загрузка...",
    or: "Или",
    main: "Главная",
    yandex: "Яндекс.Практикум",
    savedArticles: "Сохранённые статьи",
    removeFromSaved: "Убрать из сохранённых",
    signInToSave: "Войдите, чтобы сохранять статьи",
    isSearching: "Идет поиск новостей...",
    notFoundTitle: "Ничего не найдено",
    notFoundText: "К сожалению, по вашему запросу ничего не найдено.",
    errorTitle: "Во время запроса произошла ошибка",
    errorText: "Возможно, проблема с соединением или сервер недоступен. Подождите немного и попробуйте ещё раз.",
    results: "Результаты поиска",
    showMore: "Показать еще",
    collapse: "Свернуть",
    youHave: "у вас",
    and: "и",
    savedArticle: "сохранённая статья",
    savedArticle2: "сохранённые статьи",
    savedArticle3: "сохранённых статей",
    byKeyword: "По ключевому слову: ",
    byKeywords: "По ключевым словам: ",
    more: "-у другому",
    more2: "-м другим",
    more3: "-и другим",
    title: "Что творится в мире?",
    subtitle: "Находите самые свежие статьи на любую тему и сохраняйте в своём личном кабинете.",
    searchPlaceholder: "Введите тему новости",
    searchButton: "Искать",
    about: "Об авторе",
    aboutMe: "Привет! Меня зовут Менахем Нейман, я фронтенд разработчик, учился в Яндекс.Практикум.",
    aboutMe2: "Работаю на React, Express, Node, знаю JavaScript, HTML и CSS.",
  },
};
